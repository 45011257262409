<template>
  <AppContainer id="upcoming-all" class="my-5">
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select v-model="sortBy" id="sortBySelect" :options="sortOptions" class="w-75">
              <template v-slot:first>
                <option value>-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="initialSortSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="sortDirection"
            id="initialSortSelect"
            size="sm"
            :options="['asc', 'desc', 'last']"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter On"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          description="Leave all unchecked to filter on all data"
          class="mb-0"
        >
          <b-form-checkbox-group v-model="filterOn" class="mt-1">
            <b-form-checkbox value="upcoming_id">TID</b-form-checkbox>
            <b-form-checkbox value="title">Title</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      show-empty
      small
      stacked="md"
      :items="upcomings"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filterIncludedFields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click="row.toggleDetails"
          class="mr-1"
        >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
        <b-button @click="onRemove(row.item)" size="sm" variant="danger" class="mr-1">Remove</b-button>
        <b-button :to="'/upcomings/edit/'+row.item.upcoming_id" size="sm" variant="primary">Edit</b-button>
      </template>

      <template v-slot:row-details="row">
        <b-card>
          <b-img-lazy thumbnail fluid center :src="row.item.imgurl" alt="Center image"></b-img-lazy>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-button to="/upcomings/create">Add New upcoming</b-button>
  </AppContainer>
</template>

<script>
const AppContainer = () => import("@/core/Components/App/AppContainer.vue");
import { api } from "../../resource";
export default {
  name: "dashboard",
  data() {
    return {
      upcomings: [],
      fields: [
        {
          key: "upcoming_id",
          label: "TID",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "title",
          label: "Title"
        },
        { key: "actions", label: "Actions" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: []
    };
  },
  created() {
    api.create("upcomings/all", {}).then(res => {
      this.upcomings = res.data.upcomings;
      this.totalRows = this.upcomings.length;
    });
  },
  methods: {
    onRemove(movie) {
      api.create("upcomings/delete", movie)
      .then(() => {
        const i = this.upcomings.findIndex(m => m.upcoming_id == movie.upcoming_id);
        this.upcomings.splice(i, 1);
      });
    },
    onFiltered(filteredUpcomings) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredUpcomings.length;
      this.currentPage = 1;
    }
  },
  components: {
    AppContainer
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    }
  }
};
</script>
